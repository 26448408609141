<template>
  <div
    :class="[
      twMerge(
        'flex size-full flex-col items-center justify-center',
        $attrs.class,
      ),
    ]"
  >
    <Spinner :class="[twMerge('text-p-500 animate-spin', sizeClasses)]" />
  </div>
</template>

<script setup lang="ts">
/** packages */
import { twMerge } from 'tailwind-merge'

/** assets */
import Spinner from '~/assets/icons/Spinner.svg'

/** types */
import type { TLoaderSize } from '~/src/types/markets'

/** props */
const props = withDefaults(
  defineProps<{
    size?: TLoaderSize
  }>(),
  {
    size: 'large',
  },
)

const sizes = {
  small: 'size-8',
  large: 'size-16',
  xlarge: 'size-24',
}

/** computed */
const sizeClasses = computed(() => sizes[props.size])
</script>
